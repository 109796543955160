<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- category -->
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="tags"
                rules="required"
              >
                <b-form-group
                  :label="$t('Add_Product.Tag')"
                  label-for="tag"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    multiple
                    id="category"
                    v-model="tagSelect"
                    :state="tagSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tagOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ $t("Add_Product.Submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { required } from "@validations";

export default {
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      tagSelect: [],
      tagOptions: [
        {
          value: "select_value",
          text: "Select Value",
        },
      ],
      itemData: {
        tags: [],
      },
      required,
    };
  },
  created() {
    this.id = this.$route.params.id;
    axios
      .get("products/" + this.id)
      .then((result) => {
        const data = result.data.data;
        if (data.tags.length > 0) {
          for (let item in data.tags) {
            this.tagSelect.push({
              text: data.tags[item].name,
              value: data.tags[item].id.toString(),
            });
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
    axios
      .get("tags")
      .then((result) => {
        const data = result.data.data;
        if (data.length > 0) {
          for (let tg in data) {
            this.tagOptions.push({
              text: data[tg].name,
              value: data[tg].id.toString(),
            });
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.itemData.tags = [];
          // eslint-disable-next-line
          for (let ta in this.tagSelect) {
            this.itemData.tags.push(this.tagSelect[ta].value);
          }

          axios
            .post("products/" + this.id + "/tags/sync", this.itemData)
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>
 

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
